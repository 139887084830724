<template>
  <div class="page-container">
    <h1>404 |-.-|</h1>
    <p>抱歉，您访问的页面不存在。</p>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
  .page-container {
    text-align: center;
    margin-top: 120px;
    font-size: 36px;
    font-weight: 200;
    & h1 {
      font-weight: 200;
    }
  }
</style>
